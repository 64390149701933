<template>
  <div class="error-info" v-if="error">
    <slot></slot>
  </div>
</template>

<script>


export default {
  props: ['error'],
  data() {
    return {

    }
  }
}
</script>


<style lang="scss">
.error-info {
  margin-left: 130px;
  text-align: left;
  z-index: 2;
  color: red;
  font-size: 12px;
}
</style>